import { SvgIcon } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Cloud.styles';

export type CloudIconProps = WithStyles<typeof styles>;

const CloudIconComponent: React.FC<CloudIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      classes={{ root: classes.root }}
    >
      <path d="M12.9 4.83298C12.4467 2.53298 10.4267 0.806313 8 0.806313C6.07333 0.806313 4.4 1.89965 3.56667 3.49965C1.56 3.71298 0 5.41298 0 7.47298C0 9.67965 1.79333 11.473 4 11.473H12.6667C14.5067 11.473 16 9.97965 16 8.13965C16 6.37965 14.6333 4.95298 12.9 4.83298ZM12.6667 10.1396H4C2.52667 10.1396 1.33333 8.94631 1.33333 7.47298C1.33333 6.10631 2.35333 4.96631 3.70667 4.82631L4.42 4.75298L4.75333 4.11965C5.38667 2.89965 6.62667 2.13965 8 2.13965C9.74667 2.13965 11.2533 3.37965 11.5933 5.09298L11.7933 6.09298L12.8133 6.16631C13.8533 6.23298 14.6667 7.10631 14.6667 8.13965C14.6667 9.23965 13.7667 10.1396 12.6667 10.1396ZM8.96667 4.80631H7.03333V6.80631H5.33333L8 9.47298L10.6667 6.80631H8.96667V4.80631Z" />
    </SvgIcon>
  );
};

export const CloudIcon = withStyles(styles)(CloudIconComponent);
